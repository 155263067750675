import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Layout from '@/layouts';
import mainLayout from '@/layouts/mainLayout';
// import echartRouter from './modules/echarts';
// import componentsRouter from './modules/components';
// import nestRouter from './modules/nest';

//基础路由
export const baseRoute = [
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('@/views/register/index'),
    hidden: true
  },
  {
    name: 'cutPrint',
    path: '/tailor/cut/hide-cutPrint',
    component: () => import('@/views/print/cutPrint.vue'),
    meta: {
      title: '打印裁床单',
      icon: 'dashboard',
      base: true
    },
    hidden: true
  },
  {
    path: '/403',
    component: () => import('@/views/error/403'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/500',
    component: () => import('@/views/error/500'),
    hidden: true
  }
];

export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        name: 'index',
        path: '/index',
        component: () => import('@/views/index/index'),
        meta: {
          role: 'PD:board',
          title: '生产看板',
          icon: 'dashboard',
          base: true
        }
      },
    
      {
        path: '/tailor',
        component: mainLayout,
        meta: {
          role: 'PD:manage',
          title: '生产管理',
          icon: 'appstore'
        },
        children: [
          {
            name: 'tailorCreate',
            path: '/tailor/index/hide-create',
            component: () => import('@/views/tailor/modules/create.vue'),
            meta: {
              role: 'PD:Styles:saveOrUpdate',
              title: '新建款式',
              icon: 'file-add'
            },
            hidden: true
          },
          {
            name: 'tailorIindex',
            path: '/tailor/index',
            component: () => import('@/views/tailor/modules/index.vue'),
            meta: {
              title: '款式管理',
              icon: 'file-zip',
              role: 'PD:Styles:manage'
            }
          },
          {
            name: 'styleDetail',
            path: '/tailor/create/hide-styleDetail',
            component: () => import('@/views/tailor/modules/styleDetail.vue'),
            meta: {
              role: 'PD:Styles:manage',
              title: '款式详情',
              icon: 'dashboard'
            },
            hidden: true
          },
          {
            name: 'tailorCutManger',
            path: '/tailor/cut/manager',
            component: () => import('@/views/tailor/modules/cutManager.vue'),
            meta: {
              role: 'PD:bed:manage',
              title: '裁床管理',
              icon: 'layout'
            }
          },
          
          {
            name: 'tailorMangerDetail',
            path: '/tailor/create/hide-detail',
            component: () => import('@/views/tailor/modules/detail.vue'),
            meta: {
              base: true,
              title: '查看裁床单',
              icon: 'dashboard'
            },
            hidden: true
          },
          {
            name: 'tailorCut',
            path: '/tailor/create/hide-cut',
            component: () => import('@/views/tailor/modules/cut.vue'),
            meta: {
              role: 'PD:bed:saveOrUpdate',
              title: '裁床编菲',
              icon: 'dashboard'
            },
            hidden: true
          },
          {
            name: 'cutProcessDetail',
            path: '/tailor/create/hide-cutProcessDetail',
            component: () => import('@/views/tailor/modules/cutProcessDetail.vue'),
            meta: {
              role: 'PD:registration:manage',
              title: '登记管理',
              icon: 'dashboard'
            },
            hidden: true
          }
        ]
      },
      {
        name: 'tailorShipment',
        path: '/tailor/shipmentAll',
        component: () => import('@/views/tailor/modules/shipmentAll.vue'),
        meta: {
          role: 'PD:delivered:get',
          title: '出货管理',
          icon: 'profile'
        }
      },
     {
          name: 'tailorShipment',
          path: '/tailor/shipment',
          component: () => import('@/views/tailor/modules/shipment.vue'),
          meta: {
            role: 'PD:delivered:get',
            title: '出货记录明细',
            icon: 'profile'
          },
          hidden: true
      },
      {
        path: '/station',
        component: mainLayout,
        meta: {
          role: 'PD:salary',
          title: '薪资管理',
          icon: 'money-collect',
          base: true
        },
        children: [
          {
            name: 'salarySettlement',
            path: '/station/salarySettlement',
            component: () => import('@/views/station/modules/salarySettlement.vue'),
            meta: {
              role: 'PD:salary:accounts',
              title: '薪资结算',
              icon: 'pay-circle',
              base: true
            }
          },
          {
            name: 'salarySettleDetail',
            path: '/station/salarySettlement/hide-salarySettleDetail',
            component: () => import('@/views/station/modules/salarySettlementDetail.vue'),
            meta: {
              role: 'PD:salary:detail',
              title: '薪资明细',
              icon: 'dashboard',
              base: true
            },
            hidden: true
          },

          {
            name: 'salarySettleLog',
            path: '/station/salarySettlement/salarySettleLog',
            component: () => import('@/views/station/modules/salarySettleLog.vue'),
            meta: {
              role: 'PD:salary:detail:record',
              title: '结算记录',
              icon: 'file-text'
            }
          }
        ]
      },
      {
        name: 'bill',
        component: mainLayout,
        path: '/bill',
        redirect: '/bill/index',
        meta: {
          role: 'PD:outsource:manage',
          title: '外发管理',
          icon: 'container'
        },
        children: [
          {
            name: 'billManagerIndex',
            path: '/billManager/index',
            component: () => import('@/views/system/billManager/index.vue'),
            meta: {
              role: 'PD:outsource:query',
              title: '裁片外发',
              icon: 'file-text'
            }
          },
          // {
          //   name: 'billManagerMaterial',
          //   path: '/billManager/material',
          //   component: () => import('@/views/system/billManager/material.vue'),
          //   meta: {
          //     base: true,
          //     title: '物料外发',
          //     icon: 'file-text'
          //   }
          // },
          {
            name: 'billFactoryManager',
            path: '/billFactoryManager/index',
            component: () => import('@/views/system/billFactoryManager/index.vue'),
            meta: {
              role: 'PD:enterprise:manage',
              title: '外发工厂',
              icon: 'tags'
            }
          }
        ]
      },
      {
        name: 'account',
        component: mainLayout,
        path: '/account',
        redirect: '/account/order',
        meta: {
          role: 'PD:bill',
          title: '账单管理',
          icon: 'database'
        },
        children: [
          {
            name: 'accountManagerOrder',
            path: '/accountManager/order',
            component: () => import('@/views/system/accountManager/order.vue'),
            meta: {
              role: 'PD:bill',
              title: '订单对账',
              icon: 'file-text'
            }
          },
          {
            name: 'accountManagerIndex',
            path: '/accountManager/index',
            component: () => import('@/views/system/accountManager/index.vue'),
            meta: {
              role: 'PD:bill',
              title: '裁片外发对账',
              icon: 'project'
            }
          }
          // {
          //   name: 'detailBill',
          //   path: '/accountManager/index/hide-detailBill',
          //   component: () => import('@/views/system/accountManager/detailBill.vue'),
          //   meta: {
          //     role: 'PD:Styles:details',
          //     title: '账单明细',
          //     icon: 'dashboard'
          //   },
          //   hidden: true
          // }
          // {
          //   name: 'accountManagerMaterial',
          //   path: '/accountManager/material',
          //   component: () => import('@/views/system/accountManager/material.vue'),
          //   meta: {
          //     base: true,
          //     title: '物料外发对账',
          //     icon: 'file-text'
          //   }
          // }
        ]
      },
      {
        name: 'employeeManage',
        path: '/system/employeeManage',
        component: () => import('@/views/system/employeeManage/index.vue'),
        meta: { title: '员工管理', role: 'PD:employee:query', icon: '', svg: 'user' }
      },

      {
        name: 'customManage',
        path: '/system/customManage',

        component: () => import('@/views/system/customManage/index.vue'),
        meta: { title: '客户管理', role: 'PD:customer:query', icon: '', svg: 'customer', flag: true }
      },
      {
        name: 'stationManage',
        path: '/system/stationManage',
        component: () => import('@/views/system/stationManage/index.vue'),
        meta: { title: '可见工序', role: 'PD:posts:query', icon: '', svg: 'position' }
      },
      {
        name: 'system',
        component: mainLayout,
        path: '/system',
        redirect: '/system/stationManage',
        meta: {
          title: '更多功能',
          icon: 'setting',
          base: true
        },
        children: [
          // {
          //   name: 'logManage',
          //   path: '/system/logManage',
          //   component: () => import('@/views/system/logManage/index.vue'),
          //   meta: { title: '操作日志', role: 61 ,icon:'profile'}
          // },
          {
            name: 'authManage',
            path: '/system/authManage',
            component: () => import('@/views/system/authManage/index.vue'),
            meta: { title: '权限管理', role: 'PD:authority:manage', icon: '', svg: 'position' }
          },
          {
            name: 'printManage',
            path: '/system/printManager',
            component: () => import('@/views/system/printManager/index.vue'),
            meta: { title: '打印机', icon: 'profile', base: true }
          },
          {
            name: 'viedoManage',
            path: '/system/teachManager',
            component: () => import('@/views/system/teachManager/index.vue'),
            meta: { title: '视频教程', icon: 'zhihu-circle', base: true }
          }
        ]
      },
      {
        path: '/userSystem',
        component: mainLayout,
        meta: {
          title: '我的',
          icon: 'appstore',
          role: 'PD:enterprise:information'
        },
        hidden: true,
        children: [
          {
            name: 'userInfo',
            path: '/userInfo/hide-userInfo',
            component: () => import('@/views/system/userManage/index.vue'),
            meta: {
              role: 'PD:enterprise:information',
              title: '企业信息',
              icon: 'file-add'
            }
          }
        ]
      }
    ]
  },
  {
    name: 'tailorShipmentDetail',
    path: '/shipmentPrint',
    component: () => import('@/views/print/shipmentPrint.vue'),
    hidden: true,
    meta: {
      // role: 'PD:bed:note',
      title: '打印出库记录',
      icon: 'dashboard',
      base: true
    }
  },
  {
    name: 'salaryPrint',
    path: '/salaryPrint',
    component: () => import('@/views/print/salaryPrint.vue'),
    hidden: true,
    meta: {
      // role: 'PD:bed:note',
      title: '打印工资条',
      icon: 'dashboard',
      base: true
    }
  },
  {
    path: '/tailor-print',
    component: () => import('@/views/print/tailorPrint.vue'),
    hidden: true,
    meta: {
      // role: 'PD:bed:print',
      title: '打印票菲',
      icon: 'dashboard',
      base: true
    }
  },

  // {
  //   name: 'salarySettlePrint',
  //   path: '/station/salarySettlement/hide-salarySettlePrint',
  //   component: () => import('@/views/station/modules/salarySettlePrint.vue'),
  //   meta: {
  //     // role: 'PD:salary:scan',
  //     title: '薪资明细打印',
  //     icon: 'dashboard',
  //     base: true
  //   },
  //   hidden: true
  // },
  {
    name: 'salarySalaryPrint',
    path: '/station/salarySettlement/hide-salarySalaryPrint',
    component: () => import('@/views/station/modules/salarySalaryPrint.vue'),
    meta: {
      // role: 'PD:salary:sheet',
      title: '工资条打印',
      icon: 'dashboard',
      base: true
    },
    hidden: true
  },

  { path: '*', redirect: '/403', hidden: true }
];

const createRouter = function () {
  return new VueRouter({
    routes: baseRoute,
    scrollBehavior: () => ({ y: 0 })
  });
};

const router = createRouter();

export function resetRouter() {
  router.matcher = createRouter().matcher;
}

//重定向时报错，用这个不让他报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

export default router;
